import { useState } from 'react'
import { getAppsFlyerDynamicLinkFromCookiesOnPageReLoad } from '@framework/utils/app-util'
import Cookies from 'js-cookie'
import { UtmAppsFlyerCookieKey } from '@components/utils/constants'

const OPEN_LINK_URL = 'https://damensch.onelink.me/lgFk'
const params = {
  dynamicLink: '',
  utc: new Date().toISOString(),
  redirect: false,
}
const MEDIA_SOURCE = {
  keys: ['utm_source', 'utm_medium', 'utm_campaign'],
  defaultValue: 'default-ss',
}
const GOOGLE_CLICK_ID_KEY = 'af_sub1'
const AF_SUB_2 = { keys: ['fbclid'] }
const CUSTOM_SS_UI = { paramKey: 'af_ss_ui', defaultValue: 'true' }

const useAppFlyer = () => {
  const [appleDynamicLink, setAppleDynamicLink] = useState('')

  const setDynamicLinkOnButton = (newDynamicLink: string) => {
    const downloadAppLinkElement: HTMLAnchorElement = document.getElementById(
      'downloadAppLink'
    ) as HTMLAnchorElement
    const androidDownloadAppLinkElement: HTMLAnchorElement =
      document.getElementById('androidDownloadApp') as HTMLAnchorElement

    if (newDynamicLink !== '') {
      if (downloadAppLinkElement) {
        downloadAppLinkElement.setAttribute('href', newDynamicLink)
        downloadAppLinkElement.href = newDynamicLink
      }
      if (androidDownloadAppLinkElement) {
        androidDownloadAppLinkElement.setAttribute('href', newDynamicLink)
        androidDownloadAppLinkElement.href = newDynamicLink
      }
    } else {
      console.error(':: no dynamic link')
    }
  }

  const setAppleDynamicLinkOnButton = (newDynamicLink: string) => {
    const iosDownloadAppLinkElement: HTMLAnchorElement =
      document.getElementById('iosDownloadApp') as HTMLAnchorElement
    if (newDynamicLink !== '') {
      if (iosDownloadAppLinkElement) {
        iosDownloadAppLinkElement.setAttribute('href', newDynamicLink)
        iosDownloadAppLinkElement.href = newDynamicLink
      }
    }
  }

  const generateAppFlyerDynamicLink = (window?: any) => {
    if (typeof window != undefined) {
      const CAMPAIGN = { keys: ['utm_campaign'] }
      const UTMDA_MEDIUM = { paramKey: 'utmda_medium', keys: ['utm_medium'] }
      const result = window?.AF_SMART_SCRIPT?.generateOneLinkURL({
        oneLinkURL: OPEN_LINK_URL,
        afParameters: {
          mediaSource: MEDIA_SOURCE,
          googleClickIdKey: GOOGLE_CLICK_ID_KEY,
          afSub2: AF_SUB_2,
          campaign: CAMPAIGN,
          afCustom: [CUSTOM_SS_UI, UTMDA_MEDIUM],
        },
      })

      let result_url = ''
      if (result) {
        result_url = result.clickURL
        params.dynamicLink = result_url
        Cookies.set(UtmAppsFlyerCookieKey, JSON.stringify(params))
        setAppleDynamicLinkOnButton(result_url)
        setDynamicLinkOnButton(result_url)
        setAppleDynamicLink(result_url)
      }
    }
  }

  const checkIsAppFlyerDynamicLink = async () => {
    if (!appleDynamicLink) {
      const appsFlyerDynamicLinkFromCookiesOnPageReLoad =
        await getAppsFlyerDynamicLinkFromCookiesOnPageReLoad()
      setAppleDynamicLink(appsFlyerDynamicLinkFromCookiesOnPageReLoad)
      setDynamicLinkOnButton(appsFlyerDynamicLinkFromCookiesOnPageReLoad)
      setAppleDynamicLinkOnButton(appsFlyerDynamicLinkFromCookiesOnPageReLoad)
    }
  }

  return { generateAppFlyerDynamicLink, checkIsAppFlyerDynamicLink }
}

export { useAppFlyer }
