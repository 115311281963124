import { MOENGAGE_DISABLED } from '@framework/utils/constants'
import { MO_ENGAGE_EVENT } from './moengage_event.modal'


const useMoEngageEvent = () => {
  return (eventName: MO_ENGAGE_EVENT, params?: Object, window?: any) => {
    if (!MOENGAGE_DISABLED && window && window?.Moengage) {
      window?.Moengage.track_event(eventName, params)
    }
  }
}

export { useMoEngageEvent }
