import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosError,
  Method,
  ResponseType,
} from 'axios'
import HttpError from 'standard-http-error'
import get from 'lodash/get'
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
  DocumentNode,
  NormalizedCacheObject,
  ApolloError,
} from '@apollo/client'

import DamenschHttpError, {
  prepareErrorPropsFromAxiosError,
} from './http-error'
import packageData from '../package.json'

import GlobalConfig from './globalConfig'
import { print } from 'graphql'
import { isEmpty } from '@framework/utils/lodash'
import Cookies from 'js-cookie'
import { LocalStorage } from '@components/utils/payment-constants'

interface IGraphQLRequest {
  query: DocumentNode
  variables?: {}
  headers?: {}
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    return Promise.reject(error)
  }
)

export const isAxiosError = (arg: any): arg is AxiosError => {
  if (arg.isAxiosError === true) {
    return true
  }

  return false
}

export const isApolloError = (arg: any): arg is ApolloError => {
  if (arg.networkError) {
    return true
  }

  return false
}

export const prepareRequestHeaders = (headers: any = {}) => {
  const modifiedHeaders = { ...headers }
  const accessToken =
    Cookies.get(LocalStorage.Key.userEncryptedDetails) ||
    getLocalStorageItem(LocalStorage.Key.userEncryptedDetails)

  // Back-end needs this header to be supplied with each request
  modifiedHeaders.channel = 'web'
  if (accessToken) {
    if (modifiedHeaders) modifiedHeaders.auth_token = accessToken
  }

  return modifiedHeaders
}

const httpLink = new HttpLink({
  // uri: 'http://localhost:8000/damensch-bff/graphql/graphql',
  uri: GlobalConfig.getApiUrlFromRoot('graphql'),
})

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...prepareRequestHeaders(),
    },
  }))
  return forward(operation)
})

export const client = new ApolloClient<NormalizedCacheObject>({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== 'production',
  name: packageData.name,
  version: packageData.version,
})

export const throwHttpError = (error: AxiosError | ApolloError | any) => {
  console.error(error)
  if (isAxiosError(error)) {
    const errorProps = prepareErrorPropsFromAxiosError(error)

    const httpError = new DamenschHttpError(
      errorProps.statusCode,
      errorProps.message ?? error.message,
      errorProps
    )

    throw httpError
  } else if (isApolloError(error)) {
    if (error.networkError) {
      const httpError = new HttpError(
        (error.networkError as any).statusCode,
        error.networkError?.message,
        (error.networkError as any).result ?? undefined
      )

      throw httpError
    } else {
      const httpError = new HttpError(200, 'Error in GraphQL Response', error)

      throw httpError
    }
  } else {
    const httpError = new HttpError(
      200,
      error.message ?? 'Error in Success HTTP Response',
      error
    )

    throw httpError
  }
}

export const successResponse = (response: any) => {
  if (response.data instanceof ArrayBuffer || response.data instanceof Blob) {
    return response
  }
  if (response?.data?.errors || (response.errors && !response.data)) {
    const errors = response?.data?.errors ?? response.errors

    throwHttpError(errors[0])
  }

  return {
    code: get(response, 'data.code'),
    message: get(response, 'data.message'),
    data: response?.data?.data ?? response.data,
  }
}

export interface IRequestConfig {
  method?: Method
  url?: string
  responseType?: ResponseType
  headers?: Record<string, string>
  data?: any
}

export const prepareAxiosConfiguration = (
  request: IRequestConfig
): AxiosRequestConfig => {
  const { method, responseType = 'json', data, headers, url } = request

  const requestHeaders = prepareRequestHeaders(headers)

  let requestConfig: AxiosRequestConfig = {
    method,
    url,
    responseType,
    headers: requestHeaders,
  }

  if (method === 'GET' && !isEmpty(data)) {
    requestConfig = {
      ...requestConfig,
      params: data,
    }
  }

  if (method !== 'GET' && (data instanceof FormData || !isEmpty(data))) {
    requestConfig = {
      ...requestConfig,
      data,
    }
  }

  return requestConfig
}

const httpRequest = async (request: any) => {
  const axiosConfig = prepareAxiosConfiguration(request)

  return axios.request(axiosConfig).then(successResponse, (error) => {
    throwHttpError(error)
  })
}

export const graphQLRequestWithApollo = async (request: {
  query: DocumentNode
  variables?: {}
  headers?: {}
}) => {
  const { query, variables, headers } = request
  const accessToken =
    Cookies.get(LocalStorage.Key.userEncryptedDetails) ||
    getLocalStorageItem(LocalStorage.Key.userEncryptedDetails)
  // const user = localStorage?.getItem('user')
  // const userDetails = user ? JSON.parse(user) : {}
  return client
    .query({
      query: query,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      ...(variables && { variables }),
      context: {
        ...(headers && {
          headers: {
            ...headers,
            auth_token: accessToken,
            // userId: userDetails?.userId,
          },
        }),
      },
    })
    .then(successResponse, (error: any) => {
      throwHttpError(error)
    })
}

export const __graphQLRequestWithApolloWithAxios = async (
  request: IGraphQLRequest
) => {
  const graphqlRequest = {
    method: 'POST' as Method,
    headers: prepareRequestHeaders(request.headers),
    url: GlobalConfig.getApiUrlFromRoot('graphql'),
    data: {
      query: print(request.query),
      variables: request.variables,
    },
  }
  return axios
    .request(graphqlRequest)
    .then((response) => {
      return response.data
    })
    .then(successResponse, (error) => {
      throwHttpError(error)
    })
}

const getLocalStorageItem = (key: string): string | null => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return localStorage.getItem(key)
  }
  return null
}
export const graphQLMutationWithApollo = async (request: {
  mutation: DocumentNode
  variables?: {}
  headers?: {}
}) => {
  const { mutation, variables, headers } = request
  return client
    .mutate({
      mutation,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      ...(variables && { variables }),
      context: {
        ...(headers && {
          headers: {
            ...headers,
          },
        }),
      },
    })
    .then(successResponse, (error) => {
      throwHttpError(error)
    })
}

export default httpRequest
