import { getDomainName } from '@framework/utils/app-util'
import { IPerformanceEntry, IUTM, utmObj } from './utm.modal'
import { ALLOWED_UTM_QS } from 'middleware'
import { uriParams } from '@commerce/utils/uri-util'
import { stringToBoolean } from '@framework/utils/parse-util'
import Cookies from 'js-cookie'
import { UtmCookieKey } from '@components/utils/constants'

interface IUTMCookies {
  utc?: string
  origin?: string
  utm_medium?: string
  utm_source?: string
  utm_campaign?: string
  dynamicLink?: string
  utm_id?: string
  utm_term?: string
  utm_content?: string
  redirect?: boolean
}

const useUtm = () => {
  const storeUtmHandler = () => {
    const search = document.location.search
    let queryParams: IUTMCookies = {
      utc: new Date().toISOString(),
      utm_medium: undefined,
      utm_source: undefined,
      utm_campaign: undefined,
      utm_content: undefined,
      utm_id: undefined,
      utm_term: undefined,
      origin: undefined,
      dynamicLink: undefined,
      redirect: false,
    }
    let isUtmParams = false
    let isPageReloaded = false
    let isUTMParamAvailable = false
    const navigationEntries = performance.getEntriesByType('navigation')
    const referrerUrl = document.referrer
    const domain = getDomainName(referrerUrl)
    const utmData: IUTM | undefined = utmObj.find((datum: IUTM) =>
      datum.utm_medium.includes(domain.toLowerCase())
    )
    if (navigationEntries.length > 0) {
      const navigationEntry: IPerformanceEntry = navigationEntries[0]
      if (navigationEntry?.type && navigationEntry.type === 'reload') {
        // Page has been reloaded
        isPageReloaded = true
      }

      if (
        ALLOWED_UTM_QS.some((substring) =>
          navigationEntry.name.includes(substring)
        )
      ) {
        // Page has been reloaded
        isUTMParamAvailable = true
      }
    }
    if (search && !isPageReloaded) {
      const params = uriParams(search)
      queryParams.utm_medium = params?.utm_medium ?? undefined
      queryParams.utm_source = params?.utm_source ?? undefined
      queryParams.utm_campaign =
        params?.utm_campaign ?? params?.utm_source ?? undefined
      queryParams.utm_content = params?.utm_content ?? undefined
      queryParams.utm_id = params?.utm_id ?? undefined
      queryParams.utm_term = params?.utm_term ?? undefined
      queryParams.redirect = stringToBoolean(params.redirect)
      isUtmParams = false

      queryParams.origin = `${document.location.origin}/${document.location.pathname}`

      if (
        queryParams.utm_medium ||
        queryParams.utm_source ||
        queryParams.utm_campaign ||
        queryParams.utm_content ||
        queryParams.utm_id ||
        queryParams.utm_term
      ) {
        isUtmParams = true
      }
    }

    if (
      !isUtmParams &&
      !isUTMParamAvailable &&
      !queryParams.utm_source &&
      !isPageReloaded
    ) {
      const referrerUrl = document.referrer
      const origin = document.location.origin
      if (referrerUrl !== '' && !referrerUrl.includes(origin)) {
        queryParams.utm_medium = referrerUrl
        isUtmParams = true
        if (!queryParams.origin) {
          queryParams.origin = origin
        }
        if (utmData?.utm_source) {
          queryParams.utm_source = utmData.utm_source
          queryParams.utm_campaign = utmData.utm_campaign
        } else {
          queryParams.utm_source = 'Unknown_Source'
          queryParams.utm_campaign = 'Unknown_Source'
        }
      } else {
        queryParams.utm_source = 'Direct'
        queryParams.utm_campaign = 'Direct'
        queryParams.utm_medium = 'Direct'
        isUtmParams = true
      }
    }

    if (!isPageReloaded && (isUtmParams || queryParams.redirect)) {
      try {
        Cookies.set(UtmCookieKey, JSON.stringify(queryParams))
      } catch (err: any) {
        console.error(
          `:: error dynamic link: ==> ${
            err?.message ?? 'ERROR in dynamic link'
          }`
        )
      }
    }
  }

  return { storeUtmHandler }
}

export { useUtm }
