import { GA4_DISABLED } from '@framework/utils/constants'

export const recordGA4Event = (
  window: any,
  eventName: string,
  eventParams: any
) => {
  if (!GA4_DISABLED && window && window?.dataLayer) {
    try {
      window?.dataLayer?.push({
        event: eventName,
        page: eventParams,
      })
    } catch (err) {
      console.error(':: GA4 event ', err)
    }
  }
}
