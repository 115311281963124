// usePreviousPath.js
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const usePreviousPath = () => {
  const router = useRouter();
  const [previousPath, setPreviousPath] = useState('');

  useEffect(() => {
    // Update the previous path whenever the route changes
     // setPreviousPath(router.asPath);  ===> getting current page path instead of previous page path after mounting

    const handleRouteChange = (url: string) => {
      // set asPath as previous path of current page before redirect on next page
      setPreviousPath(router.asPath);
    };

    // Subscribe to route changes
    router.events.on('routeChangeStart', handleRouteChange);

    // Clean up the subscription
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.asPath]);

  return previousPath;
};

export default usePreviousPath;
