import '@assets/main.css'
import '@assets/dam.css'
import '@assets/ipad.css'
import '@assets/icon.css'
import '@assets/chrome-bug.css'
import 'swiper/css/bundle'
import { ApolloProvider } from '@apollo/client'
import { FC, useEffect, useState, useLayoutEffect } from 'react'
import { Head } from '@components/common'
import { ManagedUIContext, IDeviceInfo } from '@components/ui/context'
import Cookies from 'js-cookie'
import { v4 as uuid_v4 } from 'uuid'
import {
  SessionIdCookieKey,
  DeviceIdKey,
  isMicrosoftClarityEnable,
  microsoftClarityProjectId,
} from '@components/utils/constants'
import setSessionIdCookie, {
  createSession,
  getExpiry,
  getMinutesInDays,
  isValidSession,
} from '@components/utils/setSessionId'
import OverlayLoader from '@components/common/OverlayLoader'
import {
  APP_FLYER_DISABLED,
  GA4_MEASUREMENT_ID,
  MOENGAGE_DISABLED,
} from '@framework/utils/constants'
import { tryParseJson } from '@framework/utils/parse-util'
import InitDeviceInfo from '@components/common/InitDeviceInfo'
import { DeviceType } from '@commerce/utils/use-device'
import PasswordProtectedRoute from '@components/route/PasswordProtectedRoute'
import { ContentSnippet } from '@components/common/Content'
import { AppContext, AppInitialProps } from 'next/app'
import { decrypt, encrypt } from '@framework/utils/cipher'
import usePreviousPath from 'hooks/usePreviousPath'
import { useAppFlyer } from 'hooks/useAppFlyer'
import { useUtm } from 'hooks/useUtm'
import { client } from 'services/request.service'
import { microsoftClarity } from 'services/utils'

declare const window: any

const Noop: FC = ({ children }: any) => <>{children}</>

const setDeviceIdCookie = () => {
  if (!Cookies.get(DeviceIdKey)) {
    const deviceId = uuid_v4()
    Cookies.set(DeviceIdKey, deviceId, {
      expires: getExpiry(getMinutesInDays(365)),
    })
  }
}

function MyApp({ Component, pageProps, nav, footer, ...props }: any) {
  const [keywordsData, setKeywordsData] = useState([])

  const [deviceInfo, setDeviceInfo] = useState<IDeviceInfo>({
    isMobile: undefined,
    isOnlyMobile: undefined,
    isDesktop: undefined,
    isIPadorTablet: undefined,
    isDeviceTypeLoaded: false,
    deviceType: DeviceType.UNKNOWN,
  })
  const previousPath = usePreviousPath()

  const { checkIsAppFlyerDynamicLink, generateAppFlyerDynamicLink } =
    useAppFlyer()
  const { storeUtmHandler } = useUtm()

  const Layout = (Component as any).Layout || Noop
  const snippets = [
    ...(pageProps?.globalSnippets ?? []),
    ...(pageProps?.snippets ?? []),
    ...(pageProps?.data?.snippets ?? []),
  ]

  let appConfig: any = null
  if (pageProps?.appConfig) {
    const decryptedAppConfig = decrypt(pageProps?.appConfig)
    appConfig = tryParseJson(decryptedAppConfig)
  }

  useEffect(() => {
    if (!APP_FLYER_DISABLED) {
      checkIsAppFlyerDynamicLink()
      generateAppFlyerDynamicLink(window)
    }

    if (MOENGAGE_DISABLED) {
      window.Moengage = null
    }
    if (appConfig) {
      Cookies.set('Currency', appConfig?.defaultCurrency)
      Cookies.set('Language', appConfig?.defaultLanguage)
    }
    storeUtmHandler()
    document.body.classList?.remove('loading')
  }, [appConfig, generateAppFlyerDynamicLink, checkIsAppFlyerDynamicLink])

  const handleMSClarityInit = () => {
    if (microsoftClarityProjectId) {
      microsoftClarity.init(microsoftClarityProjectId)
      microsoftClarity.consent()
    }
  }

  useEffect(() => {
    if (isMicrosoftClarityEnable && microsoftClarityProjectId) {
      handleMSClarityInit()
    }
  }, [])

  useLayoutEffect(() => {
    // If browser session is not yet started.
    if (!isValidSession()) {
      // Initiate a new browser session.
      createSession()
    }

    setSessionIdCookie()
    setDeviceIdCookie()
    return function cleanup() {
      Cookies.remove(SessionIdCookieKey)
    }
  }, [])

  return (
    <>
      <Head {...pageProps}></Head>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GA4_MEASUREMENT_ID}`}
          height="0"
          width="0"
        ></iframe>
      </noscript>

      <div id="google_translate_element" />
      <ApolloProvider client={client}>
        <ManagedUIContext>
          <PasswordProtectedRoute config={appConfig}>
            <>
              {snippets ? <ContentSnippet {...{ snippets }} /> : <></>}
              <InitDeviceInfo setDeviceInfo={setDeviceInfo} />

              <Layout
                nav={nav}
                footer={footer}
                config={appConfig}
                pageProps={pageProps}
                keywords={[]}
                // keywords={keywordsData}
                deviceInfo={deviceInfo}
                previousPath={previousPath}
              >
                <OverlayLoader />
                <Component
                  {...pageProps}
                  config={appConfig}
                  deviceInfo={deviceInfo}
                  previousPath={previousPath}
                />
              </Layout>
            </>
            {/*})}*/}
          </PasswordProtectedRoute>
        </ManagedUIContext>
      </ApolloProvider>
    </>
  )
}

MyApp.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps> => {
  const { ctx, Component } = context
  const req: any = ctx?.req

  const headers = {
    DomainId: process.env.NEXT_PUBLIC_DOMAIN_ID,
  }

  const languageCookie =
    req?.cookies?.Language === 'undefined' ? '' : req?.cookies?.Language

  const currencyCookie =
    req?.cookies?.Currency === 'undefined' ? '' : req?.cookies?.Currency

  const countryCookie =
    req?.cookies?.Country === 'undefined' ? '' : req?.cookies?.Country

  const defaultCurrency = currencyCookie || 'INR'

  const defaultCountry = countryCookie || 'US'

  const defaultLanguage = languageCookie || 'en-GB'

  const appConfigObj = {
    ...{
      defaultCurrency,
      defaultLanguage,
      defaultCountry,
    },
  }
  const appConfig = encrypt(JSON.stringify(appConfigObj))

  return {
    pageProps: {
      appConfig: appConfig,
    },
  }
}

export default MyApp
