import React, { FC, useCallback, useMemo } from 'react'
import { ThemeProvider } from 'next-themes'
import { isDesktop, isMobile } from 'react-device-detect'
import { setItem, getItem, removeItem } from '@components/utils/localStorage'
import { v4 as uuid } from 'uuid'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { LocalStorage } from '@components/utils/payment-constants'
import {
  ALERT_TIMER,
  SHORT_ALERT_TIMER,
  Messages,
} from '@components/utils/constants'
import { LOGOUT } from '@components/utils/textVariables'
import { resetBasket } from '@framework/utils/app-util'
import { DeviceType } from '@commerce/utils/use-device'
import { getExpiry, getMinutesInDays } from '@components/utils/setSessionId'
import {
  removeSessionItem,
  setSessionItem,
} from '@components/utils/sessionStorage'
import { Cookie } from '@framework/utils/constants'

declare const window: any

export const basketId = () => {
  if (Cookies.get('basketId')) {
    return Cookies.get('basketId') || ''
  }
  const basketId = uuid()
  Cookies.set('basketId', basketId, {
    expires: getExpiry(getMinutesInDays(365)),
  })
  return basketId
}

export const storeBasketId = () => {
  if (Cookies.get('storeBasketId')) {
    return Cookies.get('storeBasketId') || ''
  }
  const storeBasketId = uuid()
  Cookies.set('storeBasketId', storeBasketId, {
    expires: getExpiry(getMinutesInDays(1)),
  })
  return storeBasketId
}

const DEFAULT_CONFIRM_DIALOG_DATA: IConfirmDialogData = {
  show: false,
  data: undefined,
  title: '',
  cancelText: 'Cancel',
  message: '',
  okCallback: () => {},
  okText: 'OK',
  cancelCallback: () => {},
}

export interface IConfirmDialogData {
  readonly show: boolean
  readonly data?: any
  readonly title: string
  readonly message: string
  readonly okText: string
  readonly cancelText: string
  okCallback: Function
  cancelCallback?: Function
}

export interface AlertRibbon {
  msg: string
  type: string
  isMobile: Boolean
}

export interface IOverlayLoaderState {
  readonly visible: boolean
  readonly message?: string
}

export interface IDeviceInfo {
  readonly isMobile: boolean | undefined
  readonly isOnlyMobile: boolean | undefined
  readonly isDesktop: boolean | undefined
  readonly isIPadorTablet: boolean | undefined
  readonly isLoadedOnMobile?: boolean | undefined
  readonly deviceType: DeviceType
  readonly isDeviceTypeLoaded: boolean
}

export interface State {
  displaySidebar: boolean
  displayDropdown: boolean
  displayModal: boolean
  displayPPGModal: boolean
  displayAlert: boolean
  displaySmallAlert: boolean
  sidebarView: string
  alertRibbon: any
  modalView: string
  userAvatar: string
  showPreparedOrder: boolean
  showLoaderInModal: boolean
  productId: string
  notifyUser: boolean
  wishListItems: any
  cartItems: any
  basketId: string
  storeBasketId: string
  storeId: string
  user: any
  isGuestUser: boolean
  showSearchBar: boolean
  appConfig: any
  orderId: string
  userIp: string
  selectedAddressId: number
  paymentMethods: Array<any>
  confirmDialogData: IConfirmDialogData
  overlayLoaderState: IOverlayLoaderState
  scrollY: number
  appliedFilters: Array<any>
  deviceInfo: IDeviceInfo
  addressesList: Array<any>
  addressScreenViewed: string
  appVersionData: any
}

const initialState = {
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  displayPPGModal: false,
  displayAlert: false,
  displaySmallAlert: false,
  alertRibbon: {},
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  userAvatar: '',
  showPreparedOrder: false,
  showLoaderInModal: false,
  productId: '',
  notifyUser: false,
  wishListItems: getItem('wishListItems') || [],
  cartItems: getItem('cartItems') || { lineItems: [] },
  basketId: basketId(),
  storeBasketId: storeBasketId(),
  storeId: null,
  user: getItem('user') || {},
  isGuestUser: getItem('isGuest') || false,
  showSearchBar: false,
  appConfig: {},
  orderId: getItem('orderId') || '',
  userIp: '',
  selectedAddressId: 0,
  paymentMethods: [],
  addressesList: [],
  addressScreenViewed: 'select_address',
  confirmDialogData: DEFAULT_CONFIRM_DIALOG_DATA,
  overlayLoaderState: {
    visible: false,
    message: '',
  },
  loaderState: false,
  scrollY: getItem('scrollY') || 0,
  appliedFilters: [],
  notifyProduct: {},
  deviceInfo: {
    isMobile: false,
    isOnlyMobile: false,
    isDesktop: false,
    isIPadorTablet: false,
    isDeviceTypeLoaded: false,
    deviceType: DeviceType.UNKNOWN,
  },
  loginCustomTitle: '',
  loginOTPCustomTitle: '',
  loginOTP: '',
  loginMobileNumber: '',
  appVersionData: null,
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'OPEN_PPG_MODAL'
    }
  | {
      type: 'CLOSE_PPG_MODAL'
    }
  | {
      type: 'OPEN_PPG_BLK_MODAL'
    }
  | {
      type: 'CLOSE_PPG_BLK_MODAL'
    }
  | {
      type: 'SHOW_ALERT'
    }
  | {
      type: 'SHOW_ALERT_SMALL_TIMER'
    }
  | {
      type: 'HIDE_ALERT'
    }
  | {
      type: 'USE_ALERT'
      payload: any
    }
  | {
      type: 'SET_STORE'
      payload: any
    }
  | {
      type: 'OPEN_NOTIFY_USER_POPUP'
      payload: string
    }
  | {
      type: 'CLOSE_NOTIFY_USER_POPUP'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_VIEWS
    }
  | {
      type: 'SET_SIDEBAR_VIEW'
      view: SIDEBAR_VIEWS
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }
  | {
      type: 'SET_SHOW_PREPARED_ORDER'
      value: boolean
    }
  | {
      type: 'SET_SHOW_LOADER_MODAL'
      value: boolean
    }
  | {
      type: 'ADD_TO_WISHLIST'
      payload: any
    }
  | {
      type: 'ADD_TO_CART'
      payload: any
    }
  | {
      type: 'REMOVE_FROM_CART'
      payload: any
    }
  | { type: 'SET_CART_ITEMS'; payload: any }
  | {
      type: 'SET_USER'
      payload: any
    }
  | {
      type: 'SET_IS_GUEST_USER'
      payload: boolean
    }
  | { type: 'REMOVE_USER'; payload: any }
  | { type: 'SET_WISHLIST'; payload: any }
  | { type: 'SET_BASKET_ID'; payload: string }
  | { type: 'SET_STORE_BASKET_ID'; payload: string }
  | { type: 'SHOW_SEARCH_BAR'; payload: boolean }
  | { type: 'SET_APP_CONFIG'; payload: any }
  | { type: 'SET_ORDER_ID'; payload: any }
  | { type: 'SET_USER_IP'; payload: string }
  | { type: 'SET_SELECTED_ADDRESS_ID'; payload: number }
  | { type: 'SET_PAYMENT_METHODS'; payload: Array<any> }
  | { type: 'SET_CONFIRM_DIALOG_DATA'; payload: any }
  | { type: 'SET_OVERLAY_STATE'; payload: IOverlayLoaderState }
  | { type: 'SET_SCROLL_Y'; payload: number }
  | { type: 'SET_FILTERS'; payload: Array<any> }
  | { type: 'SETUP_DEVICE_INFO'; payload: IDeviceInfo }
  | { type: 'SET_NOTIFY_PRODUCT'; payload: any }
  | { type: 'SET_LOADER_STATE'; payload: boolean }
  | { type: 'SET_LOGIN_CUSTOM_TITLE'; payload: string }
  | { type: 'SET_LOGIN_OTP_CUSTOM_TITLE'; payload: string }
  | { type: 'SET_LOGIN_OTP'; payload: string }
  | { type: 'SET_LOGIN_MOBILE_NUMBER'; payload: string }
  | { type: 'USE_TIMER_VALUE'; payload: any }
  | { type: 'SET_ADDRESSES_LIST'; payload: Array<any> }
  | { type: 'SET_ADDRESS_SCREEN_VIEWED'; payload: string }
  | { type: 'APP_VERSION_DATA'; payload: any }

type MODAL_VIEWS =
  | 'SIGNUP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGOT_VIEW'
  | 'NEW_SHIPPING_ADDRESS'
  | 'NEW_PAYMENT_METHOD'
  | 'NOTIFY_USER'

type SIDEBAR_VIEWS =
  | 'CART_VIEW'
  | 'CHECKOUT_VIEW'
  | 'PAYMENT_METHOD_VIEW'
  | 'WISHLIST_VIEW'
  | 'LOGIN_VIEW'
  | 'GUEST_LOGIN_VIEW'
  | 'NEW_LOGIN_VIEW'
  | ''

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SETUP_DEVICE_INFO': {
      return {
        ...state,
        deviceInfo: state?.deviceInfo,
      }
    }

    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'SET_USER_IP': {
      return {
        ...state,
        userIp: action.payload,
      }
    }

    case 'SET_SELECTED_ADDRESS_ID': {
      return {
        ...state,
        selectedAddressId: action.payload,
      }
    }

    case 'SET_PAYMENT_METHODS': {
      return {
        ...state,
        paymentMethods: action.payload,
      }
    }

    case 'SET_ADDRESSES_LIST': {
      return {
        ...state,
        addressesList: action.payload,
      }
    }

    case 'SET_ADDRESS_SCREEN_VIEWED': {
      return {
        ...state,
        addressScreenViewed: action.payload,
      }
    }

    case 'APP_VERSION_DATA': {
      return {
        ...state,
        appVersionData: action.payload,
      }
    }

    case 'SET_OVERLAY_STATE': {
      return {
        ...state,
        overlayLoaderState: action.payload,
      }
    }

    case 'SET_LOADER_STATE': {
      return {
        ...state,
        loaderState: action.payload,
      }
    }

    case 'SET_CONFIRM_DIALOG_DATA': {
      return {
        ...state,
        confirmDialogData: action.payload,
      }
    }

    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'OPEN_PPG_MODAL': {
      return {
        ...state,
        displayPPGModal: true,
        displaySidebar: false,
      }
    }
    case 'OPEN_PPG_BLK_MODAL': {
      return {
        ...state,
        displayPPGBLKModal: true,
        displaySidebar: false,
      }
    }
    case 'SHOW_ALERT': {
      return {
        ...state,
        displayAlert: true,
      }
    }
    case 'SHOW_ALERT_SMALL_TIMER': {
      return {
        ...state,
        displaySmallAlert: true,
      }
    }
    case 'HIDE_ALERT': {
      return {
        ...state,
        displayAlert: false,
        displaySmallAlert: false,
      }
    }
    case 'USE_ALERT': {
      return {
        ...state,
        alertRibbon: action.payload,
      }
    }
    case 'SET_STORE': {
      return {
        ...state,
        storeId: action.payload,
      }
    }
    case 'OPEN_NOTIFY_USER_POPUP': {
      return { ...state, notifyUser: true, productId: action.payload }
    }
    case 'CLOSE_NOTIFY_USER_POPUP': {
      return { ...state, notifyUser: false }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'CLOSE_PPG_MODAL': {
      return {
        ...state,
        displayPPGModal: false,
      }
    }
    case 'CLOSE_PPG_BLK_MODAL': {
      return {
        ...state,
        displayPPGBLKModal: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarView: action.view,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case 'SET_SHOW_PREPARED_ORDER': {
      return {
        ...state,
        showPreparedOrder: action.value,
      }
    }
    case 'SET_SHOW_LOADER_MODAL': {
      return {
        ...state,
        showLoaderInModal: action.value,
      }
    }
    case 'ADD_TO_WISHLIST': {
      return {
        ...state,
        wishListItems: [...state.wishListItems, action.payload],
      }
    }
    case 'SET_WISHLIST': {
      return {
        ...state,
        wishListItems: action.payload,
      }
    }
    case 'ADD_TO_CART': {
      return {
        ...state,
        cartItems: {
          ...state.cartItems,
          lineItems: [...state.cartItems.lineItems, action.payload],
        },
      }
    }
    case 'SET_CART_ITEMS': {
      return {
        ...state,
        cartItems: action.payload,
      }
    }
    case 'REMOVE_FROM_CART': {
      return {
        ...state,
        cartItems: state.cartItems.lineItems.filter(
          (cartItem: any) => cartItem.id !== action.payload
        ),
      }
    }
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload,
      }
    }
    case 'SET_IS_GUEST_USER': {
      return {
        ...state,
        isGuestUser: action.payload,
      }
    }
    case 'REMOVE_USER': {
      return {
        ...state,
        user: {},
      }
    }
    case 'SET_BASKET_ID': {
      return {
        ...state,
        basketId: action.payload,
      }
    }
    case 'SET_STORE_BASKET_ID': {
      return {
        ...state,
        storeBasketId: action.payload,
      }
    }
    case 'SHOW_SEARCH_BAR': {
      return {
        ...state,
        showSearchBar: action.payload,
      }
    }
    case 'SET_APP_CONFIG': {
      return {
        ...state,
        appConfig: action.payload,
      }
    }
    case 'SET_ORDER_ID': {
      return {
        ...state,
        orderId: action.payload,
      }
    }

    case 'SET_SCROLL_Y': {
      return {
        ...state,
        scrollY: action.payload,
      }
    }
    case 'SET_FILTERS': {
      return {
        ...state,
        appliedFilters: action.payload,
      }
    }
    case 'SET_NOTIFY_PRODUCT': {
      return {
        ...state,
        notifyProduct: action.payload,
      }
    }
    case 'SET_LOGIN_CUSTOM_TITLE': {
      return {
        ...state,
        loginCustomTitle: action.payload,
      }
    }
    case 'SET_LOGIN_OTP_CUSTOM_TITLE': {
      return {
        ...state,
        loginOTPCustomTitle: action.payload,
      }
    }
    case 'SET_LOGIN_OTP': {
      return {
        ...state,
        loginOTP: action.payload,
      }
    }
    case 'SET_LOGIN_MOBILE_NUMBER': {
      return {
        ...state,
        loginMobileNumber: action.payload,
      }
    }
    case 'USE_TIMER_VALUE': {
      return {
        ...state,
        timerValue: action.payload,
      }
    }
  }
}

export const UIProvider: FC = (props) => {
  const Router = useRouter()

  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const setupDeviceInfo = useCallback(() => {
    const UA = navigator.userAgent
    const isIPadorTablet = /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)

    /**
     * Determine the mobile operating system.
     * This function returns one of 'IOS', 'ANDROID', 'WINDOWS_PHONE', or 'UNKNOWN'.
     *
     * @returns {String}
     */
    const getDeviceType = () => {
      var userAgent = navigator.userAgent || navigator.vendor || window?.opera

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return DeviceType.WINDOWS_PHONE
      }

      if (/android/i.test(userAgent)) {
        return DeviceType.ANDROID
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window?.MSStream) {
        return DeviceType.IOS
      }

      return DeviceType.UNKNOWN
    }
    const deviceTypeInfo = getDeviceType()
    const isOnlyMobile = (isMobile && !isIPadorTablet) || deviceTypeInfo === 2

    const payload: IDeviceInfo = {
      isMobile,
      isOnlyMobile,
      isDesktop: isMobile || isIPadorTablet ? false : isDesktop,
      isIPadorTablet,
      deviceType: deviceTypeInfo,
      isDeviceTypeLoaded: !!(isMobile || isIPadorTablet || isDesktop),
    }
    setItem('deviceInfo', payload)
    dispatch({ type: 'SETUP_DEVICE_INFO', payload })
  }, [dispatch])

  const addToWishlist = useCallback(
    (payload: any) => {
      const storedItems = getItem('wishListItems') || []
      setItem('wishListItems', [...storedItems, payload])
      dispatch({ type: 'ADD_TO_WISHLIST', payload })
    },
    [dispatch]
  )

  const setUserIp = useCallback(
    (payload: string) => {
      dispatch({ type: 'SET_USER_IP', payload })
    },
    [dispatch]
  )

  const setAddressId = useCallback(
    (payload: number) => {
      dispatch({ type: 'SET_SELECTED_ADDRESS_ID', payload })
    },
    [dispatch]
  )

  const setPaymentMethods = useCallback(
    (payload: Array<any>) => {
      dispatch({ type: 'SET_PAYMENT_METHODS', payload })
    },
    [dispatch]
  )

  const setAddressesList = useCallback(
    (payload: Array<any>) => {
      dispatch({ type: 'SET_ADDRESSES_LIST', payload })
    },
    [dispatch]
  )

  const setAddressScreenViewed = useCallback(
    (payload: string) => {
      dispatch({ type: 'SET_ADDRESS_SCREEN_VIEWED', payload })
    },
    [dispatch]
  )

  const setOverlayLoaderState = useCallback(
    (payload: IOverlayLoaderState) => {
      dispatch({ type: 'SET_OVERLAY_STATE', payload })
    },
    [dispatch]
  )

  const setLoaderState = useCallback(
    (payload: boolean) => {
      dispatch({ type: 'SET_LOADER_STATE', payload })
    },
    [dispatch]
  )

  const hideOverlayLoaderState = useCallback(
    (
      payload: IOverlayLoaderState = {
        visible: false,
        message: '',
      }
    ) => {
      const data: IOverlayLoaderState = {
        visible: false,
        message: '',
      }
      dispatch({ type: 'SET_OVERLAY_STATE', payload })
    },
    [dispatch]
  )

  const setConfirmDialogData = useCallback(
    (payload: IConfirmDialogData) => {
      dispatch({ type: 'SET_CONFIRM_DIALOG_DATA', payload })
    },
    [dispatch]
  )

  const resetConfirmDialogData = useCallback(() => {
    const payload = DEFAULT_CONFIRM_DIALOG_DATA
    dispatch({ type: 'SET_CONFIRM_DIALOG_DATA', payload })
  }, [dispatch])

  const removeFromWishlist = useCallback(
    (payload: any) => {
      const items = state.wishListItems.filter(
        (item: any) => item.recordId !== payload
      )
      dispatch({ type: 'SET_WISHLIST', payload: items })
      setItem('wishListItems', items)
    },
    [dispatch]
  )

  const openSidebar = useCallback(
    () => dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch]
  )

  const openNotifyUser = useCallback(
    (payload: any) => dispatch({ type: 'OPEN_NOTIFY_USER_POPUP', payload }),
    [dispatch]
  )
  const closeNotifyUser = useCallback(
    () => dispatch({ type: 'CLOSE_NOTIFY_USER_POPUP' }),
    [dispatch]
  )
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch]
  )
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: 'CLOSE_SIDEBAR' })
        : dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )

  const openDropdown = useCallback(
    () => dispatch({ type: 'OPEN_DROPDOWN' }),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({ type: 'CLOSE_DROPDOWN' }),
    [dispatch]
  )

  const showAlert = useCallback(() => {
    dispatch({ type: 'SHOW_ALERT' })
    // const closeAlert = dispatch({type:'HIDE_ALERT'})
    setTimeout(hideAlert, ALERT_TIMER)
  }, [dispatch])

  const showAlertLessTime = useCallback(() => {
    dispatch({ type: 'SHOW_ALERT_SMALL_TIMER' })
    // const closeAlert = dispatch({type:'HIDE_ALERT'})
    setTimeout(hideAlert, SHORT_ALERT_TIMER)
  }, [dispatch])

  const hideAlert = useCallback(
    () => dispatch({ type: 'HIDE_ALERT' }),
    [dispatch]
  )
  const setAlert = useCallback(
    (payload: any) => {
      showAlert()
      dispatch({ type: 'USE_ALERT', payload })
    },
    [dispatch]
  )

  const setAlertWithLessTimer = useCallback(
    (payload: any) => {
      showAlertLessTime()
      dispatch({ type: 'USE_ALERT', payload })
    },
    [dispatch]
  )

  const setStore = useCallback(
    (payload: any) => {
      dispatch({ type: 'SET_STORE', payload })
    },
    [dispatch]
  )

  const setTimerValue = useCallback(
    (payload: any) => {
      showAlertLessTime()
      dispatch({ type: 'USE_TIMER_VALUE', payload })
    },
    [dispatch]
  )

  const setAppVersionData = useCallback(
    (payload: any) => {
      dispatch({ type: 'APP_VERSION_DATA', payload })
    },
    [dispatch]
  )

  const openModal = useCallback(
    () => dispatch({ type: 'OPEN_MODAL' }),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({ type: 'CLOSE_MODAL' }),
    [dispatch]
  )

  const openPPGErrorModal = useCallback(
    () => dispatch({ type: 'OPEN_PPG_MODAL' }),
    [dispatch]
  )
  const closePPGErrorModal = useCallback(
    () => dispatch({ type: 'CLOSE_PPG_MODAL' }),
    [dispatch]
  )

  const openPPGBlockedModal = useCallback(
    () => dispatch({ type: 'OPEN_PPG_BLK_MODAL' }),
    [dispatch]
  )
  const closePPGBlockedModal = useCallback(
    () => dispatch({ type: 'CLOSE_PPG_BLK_MODAL' }),
    [dispatch]
  )

  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: 'SET_USER_AVATAR', value }),
    [dispatch]
  )

  const setShowLoaderInModal = useCallback(
    (value: boolean) => dispatch({ type: 'SET_SHOW_LOADER_MODAL', value }),
    [dispatch]
  )

  const setShowPreparedOrder = useCallback(
    (value: boolean) => dispatch({ type: 'SET_SHOW_PREPARED_ORDER', value }),
    [dispatch]
  )

  const setModalView = useCallback(
    (view: MODAL_VIEWS) => dispatch({ type: 'SET_MODAL_VIEW', view }),
    [dispatch]
  )

  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEWS) => {
      if (view !== '') {
        setSessionItem(
          'sidebarType',
          view === 'LOGIN_VIEW' ||
            view === 'GUEST_LOGIN_VIEW' ||
            view === 'NEW_LOGIN_VIEW'
            ? view
            : null
        )
      }
      dispatch({ type: 'SET_SIDEBAR_VIEW', view })
    },
    [dispatch]
  )

  const setAppConfig = useCallback(
    (payload: any) => {
      dispatch({ type: 'SET_APP_CONFIG', payload })
    },
    [dispatch]
  )

  const addToCart = useCallback(
    (payload: any) => {
      const storedItems = getItem('cartItems') || { lineItems: [] }
      setItem('cartItems', { lineItems: [...storedItems.lineItems, payload] })
      dispatch({ type: 'ADD_TO_CART', payload })
    },
    [dispatch]
  )

  const removeFromCart = useCallback(
    (payload: any) => dispatch({ type: 'REMOVE_FROM_CART', payload }),
    [dispatch]
  )

  const setShowSearchBar = useCallback(
    (payload: any) => dispatch({ type: 'SHOW_SEARCH_BAR', payload }),
    [dispatch]
  )
  const setCartItems = useCallback(
    (payload: any) => {
      const newCartDataClone: any = { ...payload }
      newCartDataClone?.lineItems?.forEach((element: any, idx: number) => {
        newCartDataClone?.lineItems?.forEach((i: any) => {
          if (element.parentProductId === i.productId) {
            i.children = i.children ? [...i.children, element] : [element]
            newCartDataClone.lineItems.splice(idx, 1)
          }
        })
      })

      const cart = { ...payload }
      setItem('cartItems', cart)

      if (cart?.lineItems?.length == 0) {
        resetBasket(setBasketId, basketId)
        // resetStoreBasket(setStoreBasketId, storeBasketId)

        /*const user = {
          ...state?.user,
          ...{
            isAssociated: false
          }
        };
        setUser(user);*/
      }
      dispatch({ type: 'SET_CART_ITEMS', payload: newCartDataClone })
    },
    [dispatch]
  )

  const resetCartItems = useCallback(
    (payload?: any) => {
      setItem('cartItems', { lineItems: [] })
      dispatch({ type: 'SET_CART_ITEMS', payload: { lineItems: [] } })
    },
    [dispatch]
  )

  const resetCartStorage = useCallback(
    (payload?: any) => {
      removeItem(LocalStorage.Key.ORDER_RESPONSE)
      removeItem(LocalStorage.Key.ORDER_PAYMENT)
      removeItem(LocalStorage.Key.CONVERTED_ORDER)
      //setItem('cartItems', { lineItems: [] })
      //dispatch({ type: 'SET_CART_ITEMS', payload: { lineItems: [] } })
    },
    [dispatch]
  )

  const setUser = useCallback(
    (payload: any) => {
      setItem('user', payload)
      dispatch({ type: 'SET_USER', payload })
    },
    [dispatch]
  )

  const setIsGuestUser = useCallback(
    (payload: boolean) => {
      setItem('isGuest', payload)
      dispatch({ type: 'SET_IS_GUEST_USER', payload })
    },
    [dispatch]
  )

  const deleteUser = useCallback(
    (payload: any) => {
      Router.push('/').then(() => {
        removeItem('user')
        removeSessionItem('goKwikAddresses')
        removeItem(LocalStorage.Key.CONVERTED_ORDER)
        removeItem(LocalStorage.Key.ORDER_RESPONSE)
        removeItem(LocalStorage.Key.ORDER_PAYMENT)
        removeItem(LocalStorage.Key.PREFERRED_PAYMENT)
        removeItem(LocalStorage.Key.DELIVERY_ADDRESS)
        removeItem(LocalStorage.Key.userEncryptedDetails)
        Cookies.remove(LocalStorage.Key.userEncryptedDetails)
        Cookies.remove('askForLoginLogout')
        dispatch({ type: 'SET_WISHLIST', payload: [] })
        setSessionItem('isUserAlreadyExist', JSON.stringify(true))
        Cookies.set(Cookie.Key.IS_EXISTING_USER, 'true')
        setItem('wishListItems', [])
        setItem('cartItems', { lineItems: [] })
        dispatch({ type: 'SET_CART_ITEMS', payload: { lineItems: [] } })
        const basketIdRef = uuid()
        Cookies.set('basketId', basketIdRef, {
          expires: getExpiry(getMinutesInDays(365)),
        })
        dispatch({ type: 'SET_BASKET_ID', payload: basketIdRef })
        dispatch({ type: 'SET_STORE_BASKET_ID', payload: basketIdRef })
        dispatch({ type: 'REMOVE_USER', payload: {} })
        setAlert({ type: 'success', msg: LOGOUT })
        // destroy moengage user session
        if (window?.Moengage) {
          window?.Moengage.destroy_session()
        }
        if (window && typeof window?.handleLogout === 'function') {
          window?.handleLogout()
        }
      })
    },
    [dispatch]
  )

  const setWishlist = useCallback(
    (payload: any) => {
      setItem('wishListItems', payload)
      dispatch({ type: 'SET_WISHLIST', payload })
    },
    [dispatch]
  )

  const openCart = () => {
    setSidebarView('CART_VIEW')
    openSidebar()
  }

  const setBasketId = useCallback(
    (basketId: string) => {
      Cookies.set('basketId', basketId, {
        expires: getExpiry(getMinutesInDays(365)),
      })
      dispatch({ type: 'SET_BASKET_ID', payload: basketId })
    },
    [dispatch]
  )

  const setStoreBasketId = useCallback(
    (storeBasketId: string) => {
      Cookies.set('storeBasketId', storeBasketId, {
        expires: getExpiry(getMinutesInDays(365)),
      })
      dispatch({ type: 'SET_STORE_BASKET_ID', payload: storeBasketId })
    },
    [dispatch]
  )

  const openWishlist = () => {
    setSidebarView('WISHLIST_VIEW')
    openSidebar()
  }

  const setOrderId = useCallback(
    (payload: string) => {
      if (payload) {
        Cookies.set('orderId', payload)
      } else {
        Cookies.remove('orderId')
      }
      dispatch({ type: 'SET_ORDER_ID', payload })
    },
    [dispatch]
  )

  const setScrollY = useCallback(
    (payload: number) => {
      setItem('scrollY', payload)
      dispatch({ type: 'SET_SCROLL_Y', payload: payload })
    },
    [dispatch]
  )

  const setAppliedFilters = useCallback(
    (payload: any) => {
      dispatch({ type: 'SET_FILTERS', payload })
    },
    [dispatch]
  )

  const setNotifyProduct = useCallback(
    (payload: any) => {
      dispatch({ type: 'SET_NOTIFY_PRODUCT', payload })
    },
    [dispatch]
  )

  const setLoginCustomTitle = useCallback(
    (payload: any) => {
      dispatch({ type: 'SET_LOGIN_CUSTOM_TITLE', payload })
    },
    [dispatch]
  )

  const setLoginOTPCustomTitle = useCallback(
    (payload: any) => {
      dispatch({ type: 'SET_LOGIN_OTP_CUSTOM_TITLE', payload })
    },
    [dispatch]
  )

  const setLoginOTP = useCallback(
    (payload: any) => {
      dispatch({ type: 'SET_LOGIN_OTP', payload })
    },
    [dispatch]
  )

  const setLoginMobileNumber = useCallback(
    (payload: any) => {
      dispatch({ type: 'SET_LOGIN_MOBILE_NUMBER', payload })
    },
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      openPPGErrorModal,
      closePPGErrorModal,
      openPPGBlockedModal,
      closePPGBlockedModal,
      setModalView,
      setSidebarView,
      setUserAvatar,
      setShowLoaderInModal,
      setShowPreparedOrder,
      openNotifyUser,
      closeNotifyUser,
      addToWishlist,
      addToCart,
      removeFromCart,
      setCartItems,
      resetCartItems,
      resetCartStorage,
      setUser,
      setIsGuestUser,
      deleteUser,
      openCart,
      openWishlist,
      setWishlist,
      removeFromWishlist,
      setBasketId,
      setStoreBasketId,
      setShowSearchBar,
      setAppConfig,
      setOrderId,
      setUserIp,
      setAddressId,
      setPaymentMethods,
      setAddressesList,
      setAddressScreenViewed,
      setConfirmDialogData,
      resetConfirmDialogData,
      showAlert,
      hideAlert,
      setAlert,
      setAlertWithLessTimer,
      setStore,
      setOverlayLoaderState,
      hideOverlayLoaderState,
      setScrollY,
      setAppliedFilters,
      setupDeviceInfo,
      setNotifyProduct,
      setLoaderState,
      setLoginCustomTitle,
      setLoginOTPCustomTitle,
      setLoginOTP,
      setLoginMobileNumber,
      setTimerValue,
      setAppVersionData,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext: FC = ({ children }) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
)
