export const setSessionItem = (key: string, data: any) => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(key, JSON.stringify(data))
  }
}

export const getSessionItem = (key: string) => {
  let item: string | null = null
  if (typeof window !== 'undefined') {
    item = sessionStorage.getItem(key)
  }
  return item
    ? item.toString() != "undefined"
      ? JSON.parse(item)
      : null
    : null
}

export const removeSessionItem = (key: string) => {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(key)
  }
}
